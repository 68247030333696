
import React from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import { Button } from '../../Core';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import tortoises from './../../../assets/image/rebrand/svg/tortoises-spotIllo.svg';
import money from './../../../assets/image/rebrand/svg/money.svg';

const Section = styled.div`
    padding: 5rem 0;
    p { padding: 0px !important; }
`;

const DarkSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  h3 { 
    padding: 0px !important;
    margin-top: -0.5rem;
  }
  @media (max-width: 576px) {
    flex-direction: column;
    padding: 15px 0px;
  }
`;

const HeroRefer = (props) => {

  return (
    <>
    <Section className="green-bg">
      <Container>
        <Row>
          <Col sm={12} lg={7}>
            <h2>
                START EARNING <br />JUST FOR REFERRING
            </h2>
            <p className="mt-50 mb-50">
              Anyone who’s anyone can earn a boost for their bank balance by referring friends, neighbours, or strangers to YouFibre. It’s not just for customers, so if a mate of yours join us, you could both earn up to £100 to boost your bank account.
            </p>
            <div className="mb-50">
              <Button onClick={() => window.location.href = 'https://youfibre.aklamio.com/?source=website_refer_cta'} role="link" className="medium-button light-bg">
                Refer&nbsp;now
              </Button>
            </div>
          </Col>
          <Col sm={12} lg={5}>
            <img src={tortoises} className="w-100"/>
          </Col>
        </Row>
      </Container>
    </Section>
    </>
  );
};

export default HeroRefer;

